<template>
    <div>
        <!-- Filters -->
        <!-- <play-list-list-filters :role-filter.sync="roleFilter" :plan-filter.sync="planFilter" :status-filter.sync="statusFilter" :role-options="roleOptions" :plan-options="planOptions" :status-options="statusOptions" @filter="fetchStoreItems" /> -->

        <!-- Table Container Card -->
        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <b-form-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" @change="changePagePlaylist({ page: 1, itemsPerPage: $event })" />
                        <label>entries</label>
                    </b-col>
                </b-row>
            </div>

            <b-table ref="refUserListTable" class="position-relative pb-3" :items="logitemslist" :per-page="perPage" responsive :fields="tableColumns" primary-key="id" show-empty empty-text="No matching records found">
                <template #cell(log_name)="data">
                    <span class="text-danger font-weight-bolder">{{ data.item.log_name }}</span>
                </template>
                <template #cell(created_at)="data">
                    <span class="text-danger">{{ data.item.created_at }}</span>
                </template>
                <template #cell(causer)="data">
                    <span class="text-success font-weight-bolder">{{ data.item.causer.name }}</span>
                </template>
                <!-- <template #cell(subject)="data">
                    <span class="text-danger">{{ data.item.causer.name }}</span>
                </template> -->
            </b-table>
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ pagination.pageStart }} to {{ pagination.pageStop }} of {{ pagination.itemsLength }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="pagination.itemsLength" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @change="changePagePlaylist({ page: $event, itemsPerPage: perPage })">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
        
    </div>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BFormSelect, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination } from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useLogList from "./useLogList";
import userStoreModule from "../user/userStoreModule";
import PlayListRepository from "@/abstraction/repository/playListRepository";
import storeRepository from "@/abstraction/repository/storeRepository";
const repository = new storeRepository();
const playlistRepository = new PlayListRepository();
export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormInput,
        BFormSelect,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,

        vSelect,
    },
    setup() {
        const {
            perPageOptions,
            pagination,
            perPage,
            sortBy,
            tableColumns,
            currentPage,
            fetchStoreItems,
            deleteStickerPack,
            logitemslist,
        } = useLogList();


        onMounted(() => {
            loadList();
        });
        const loadList = () => {
            fetchStoreItems().then((res) => {
                console.log(res);
            });
        };
        const changePagePlaylist = async (paginate) => {
            await fetchStoreItems(paginate);
        };

        const updateSort = async (id) => {
            await playlistRepository.updateSort(id);
            await fetchStoreItems({ page: currentPage, perPage: perPage });
        };
        const deleteStickerPackHandler = async (id) => {
            deleteStickerPack(id).then(() => {
                loadList();
            });
        };
        const sendToTopPackHandler = async (id) => {
            repository.packSendToTop(id).then(() => {
                loadList();
            });
        };
        return {
            logitemslist,
            pagination,
            // Filter
            avatarText,
            perPageOptions,
            changePagePlaylist,
            fetchStoreItems,
            perPage,
            currentPage,
            sortBy,
            tableColumns,
            // Extra Filters
            updateSort,
            deleteStickerPackHandler,
            sendToTopPackHandler,
        };
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

a {
    color: $red;
}
</style>
