import axios from "axios";
import url from "../url";
import { getArray, setQuery, getJson, setData, setComingSoonData, getComingSoonArray, getComingSoonJson } from "../resources/logItemResource";

export default class PlayListRepository {
    constructor(config = {}) {
        axios.defaults.errorHandle = config.errorHandle;
    }

    async index(data) {
        const params = setQuery(data);
        const response = await axios.get("api/admin-logs", { params });
        if (response.status === 200) {
            return getArray(response.data);
        }
    }
}
