import { SetPagination, SetQueriesObject } from "@/utils/setQueriesObject";

export const getJson = (data) => ({
    id: data.id,
    log_name: data.log_name,
    causer: data.causer,
    subject: data.subject,
    description: data.description,
    created_at: data.created_at,
});
export const getArray = ({ data, meta }) => {
    const pagination = SetPagination(meta);
    data = data.map((playList) => getJson(playList));
    return { data, pagination };
};
export const setData = (data) => ({
    name: data.name,
    genre_id: data.genre_id,
    slug: data.slug,
    description: data.description,
    file_batch_id: data.file_batch_id,
});
export const setQuery = (data) => SetQueriesObject(data);
