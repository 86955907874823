import {ref, watch} from '@vue/composition-api'

// Notification
import LogRepository from '@/abstraction/repository/logRepository'

const repository = new LogRepository()
export default function usePlaylistList() {
    const refplaylistListTable = ref(null)
    const logitemslist = ref([])
    const perPage = ref(10)
    const perPageOptions = [10, 25, 50, 100]
    const sortBy = ref('id')
    const currentPage = ref(1)
    const searchQuery = ref('')
    const isSortDirDesc = ref(true)
    const roleFilter = ref(null)
    const planFilter = ref(null)
    const statusFilter = ref(null)
    const pagination = ref({})

    const refetchData = () => {
        refplaylistListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
        refetchData()
    })

    // Table Handlers
    const tableColumns = [
        { key: 'log_name', sortable: true },
        { key: 'description', sortable: true },
        { key: 'created_at', sortable: true },
        { key: 'causer', sortable: true },
        // { key: 'subject', sortable: true },
    ]

   
   
   
    const fetchStoreItems = async (paginate = {page: 1, itemsPerPage: perPage.value}, filters= {}) => {
        const storeItems = await repository.index({filters, pagination: paginate})
        console.log(storeItems);
        logitemslist.value = storeItems.data
        pagination.value = storeItems.pagination
        return logitemslist
    }


    const deleteStickerPack = async (id) => {
        try {
            const response = await repository.delete(id)
            pagination.value.itemsLength--
            const index = logitemslist.value.findIndex(x => x.id === id)
            logitemslist.value.splice(index, 1)
            return response
        } catch (e) {
            return false
        }
    }

    const changePagePlaylist = async page => {
        const response = await fetchStoreItems({pagination: page})
        return response
    }

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    return {
        tableColumns,
        pagination,
        perPageOptions,
        perPage,
        sortBy,
        currentPage,
        fetchStoreItems,
        deleteStickerPack,
        logitemslist
    }
}
